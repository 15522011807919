import React, { memo, useContext, Fragment, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import {
  Panel,
  Caption,
  Grid,
  GridItem,
  Input,
  ErrorText,
  TextArea,
  Image,
  Alert,
  CalendarDatePicker,
  Label,
} from '@sdflc/ui';
import { navigate } from 'gatsby';
import { cloneDeep } from 'lodash';

import {
  MenuHeader,
  DefaultLayout,
  PageLoading,
  CartItem,
  CartTotal,
  Amount,
  PanelWithTitle,
  Select,
} from '../components';
import { withProviders } from '../hoc';
import { useDateTime } from '../hooks';
import { SquareUpContext } from '../contexts';
import { appSettings, colors } from '../config';
import shoppingCart from '../images/shopping-cart.svg';

import { getUsePos, payViaPosFromAndroid } from '../utils';
import { order } from 'styled-system';
import dayjs from 'dayjs';

const Checkout = memo((props) => {
  const {
    firstTimeLoading,
    locationDetails,
    cardForm,
    submitOrder,
    submitPayment,
    resetCart,
    cart,
    menu,
    getAvailableHours,
    isPromo,
    setPromo,
  } = useContext(SquareUpContext);
  const { dateTime } = useDateTime();
  const [userInfo, setUserInfo] = useState({});
  const [errors, setErrors] = useState({});
  const [times, setTimes] = useState([]);
  const [progress, setProgress] = useState(false);

  const { /*isOpen,*/ date, time } = dateTime;
  //const status = `Currently ${isOpen ? 'Open' : 'Closed'}`;
  const items = cart?.order?.lineItems ?? [];
  const totalOrder = cart?.order?.totalOrder ?? 0;
  const showMinimumAmountMessage = items.length === 0 || totalOrder < menu.minimumPayment;
  const currentDate = new Date(date);
  const maxDate = date ? new Date(currentDate.getTime() + 86400000 * appSettings.maxAllowedDays) : date;
  const [usePos] = useState(getUsePos());

  // Trigger times rendering on first load
  useEffect(() => {
    if (date && Object.keys(locationDetails).length && times.length === 0) {
      const availableHours = getAvailableHours(currentDate, locationDetails, time, true);
      if (availableHours.length) {
        setTimes(availableHours);
      }
    }
  }, [date, locationDetails, times]);

  useEffect(() => {
    if (progress) {
      onPayButtonClick();
    }
  }, [progress]);

  const isPromoDate = (chkDate) => {
    const chk = dayjs(chkDate);
    const promoStart = dayjs('2023-10-01');
    const promoEnd = dayjs('2023-10-30');
    return chk.isAfter(promoStart) && chk.isBefore(promoEnd);
  };

  const onPayButtonClick = async () => {
    let errors = { errors: [] };

    if (!userInfo.firstName) {
      errors.errors.push('Please enter pickup name');
    }

    if (!userInfo.firstName) {
      setProgress(false);
      setErrors({ errors: [errors] });
      return;
    }

    let pickupAt = null;

    if (userInfo.pickupTime) {
      const newDate = userInfo.selectedDay || new Date(date);
      const [hours, minutes] = userInfo.pickupTime?.split(':') ?? [0, 0];
      newDate.setHours(hours, minutes, 0, 0);
      pickupAt = newDate.toISOString();
    }

    const fulfillments = [
      {
        type: 'PICKUP',
        pickup_details: {
          schedule_type: pickupAt ? 'SCHEDULED' : 'ASAP',
          pickup_at: pickupAt,
          prep_time_duration: pickupAt ? undefined : 'P0DT0H15M0S',
          note: userInfo.note,
          recipient: {
            display_name: userInfo.firstName,
            email_address: userInfo.eMail,
            phone_number: userInfo.phoneNumber,
          },
        },
      },
    ];

    try {
      const orderInfo = await submitOrder(userInfo.firstName, fulfillments);

      if (orderInfo.errors.length !== 0) {
        setProgress(false);
        setErrors({ errors: orderInfo.errors });
        return;
      }

      const newOrder = orderInfo.getDataFirst({});

      if (!usePos) {
        const paymentInfo = await submitPayment(newOrder.id, newOrder.totalMoney, 'CARD');

        if (paymentInfo.errors.length !== 0) {
          setProgress(false);
          setErrors({ errors: paymentInfo.errors });
          return;
        }
      } else {
        payViaPosFromAndroid(newOrder);
      }

      navigate('/thank-you', {
        state: { ...cloneDeep(newOrder), ...cloneDeep(cart), fulfillments: fulfillments[0] },
      });

      resetCart();
    } catch (ex) {
      console.log('Exception:', ex.messsage);
      setProgress(false);
      return;
    }
  };

  if (firstTimeLoading) {
    return <PageLoading />;
  }

  return (
    <Fragment>
      <Panel backgroundColor={colors.lightGray3}>
        <DefaultLayout title='Checkout' hideCart>
          <MenuHeader hideTime />

          {showMinimumAmountMessage ? (
            <Panel textAlign='center'>
              <Image width='150px' src={shoppingCart} />
              <p>Please add menu items with an amount at least {<Amount value={menu.minimumPayment} />}</p>
            </Panel>
          ) : (
            <Fragment>
              <Grid justifyContent='space-around'>
                <GridItem width={['100%', '100%', '50%', '50%']}>
                  <PanelWithTitle title={`Pickup Details`}>
                    <Grid justifyContent='flex-start' wrapMode='wrap' distance='2'>
                      {!usePos && (
                        <Fragment>
                          <GridItem width={['100%']}>
                            <Label required>Select Date</Label>
                            {errors.pickupTime && <ErrorText>{errors.pickupTime}</ErrorText>}
                          </GridItem>

                          <GridItem width={['100%']}>
                            <CalendarDatePicker
                              id='pickupDate'
                              max={maxDate}
                              min={currentDate}
                              name='pickupDate'
                              onChange={(_, value) => {
                                value.setHours(0, 0, 0, 0);
                                setUserInfo({ ...userInfo, selectedDay: value });
                                setTimes(
                                  getAvailableHours(
                                    value,
                                    locationDetails,
                                    time,
                                    value.getTime() === currentDate.getTime()
                                  )
                                );
                                setPromo(isPromoDate(value));
                              }}
                              isSelectable={(chkDate) => {
                                return true; // isPromoDate(chkDate) || chkDate.getDay() === 5;
                              }}
                              value={userInfo.selectedDay}
                            />
                          </GridItem>
                          {times.length > 0 && (
                            <GridItem>
                              <Label required>Select time</Label>
                              {console.log('Times available:', times)}
                              <Select
                                name='hour'
                                value={userInfo.pickupTime}
                                searchWhenMoreThan={100}
                                onChange={(value) => setUserInfo((userInfo) => ({ ...userInfo, pickupTime: value }))}
                                options={times}
                              />
                            </GridItem>
                          )}
                        </Fragment>
                      )}

                      <GridItem width={['100%']}>
                        <Label required>Pickup Name</Label>
                        <Input
                          leftIcon='Person'
                          name='firstName'
                          placeholder='Enter full name'
                          value={userInfo.firstName}
                          hasError={!!errors.firstName}
                          onChange={(name, value) => setUserInfo((userInfo) => ({ ...userInfo, [name]: value }))}
                        />
                        {errors.firstName && <ErrorText>{errors.firstName}</ErrorText>}
                      </GridItem>

                      <GridItem width={['100%', '100%', '50%', '50%']}>
                        <Label>Phone Number</Label>
                        <Input
                          type='phone'
                          leftIcon='Phone'
                          name='phoneNumber'
                          placeholder='Enter your phone number'
                          value={userInfo.phoneNumber}
                          onChange={(name, value) => setUserInfo((userInfo) => ({ ...userInfo, [name]: value }))}
                        />
                      </GridItem>

                      <GridItem width={['100%', '100%', '50%', '50%']}>
                        <Label>Email</Label>
                        <Input
                          type='email'
                          leftIcon='Email'
                          name='eMail'
                          placeholder='Enter your email'
                          value={userInfo.eMail}
                          onChange={(name, value) => setUserInfo((userInfo) => ({ ...userInfo, [name]: value }))}
                        />
                      </GridItem>

                      <GridItem width={['100%']}>
                        <Label>Additional Notes</Label>
                        <TextArea
                          name='note'
                          value={userInfo.note}
                          onChange={(name, value) => setUserInfo((userInfo) => ({ ...userInfo, [name]: value }))}
                          rows={3}
                        />
                      </GridItem>
                    </Grid>
                  </PanelWithTitle>
                  {!usePos && <PanelWithTitle title='Payment'>{cardForm}</PanelWithTitle>}
                </GridItem>
                <GridItem width={['100%', '100%', '50%', '50%']}>
                  <PanelWithTitle title='Shopping Cart'>
                    <Grid wrapMode='wrap'>
                      <CartItem width={['100%']} />
                    </Grid>
                    <CartTotal
                      accionButton={{
                        name: usePos ? 'Pay with terminal' : 'Pay Now',
                        variant: 'warning',
                        onClick: () => setProgress(true),
                        progress: progress,
                      }}
                    />
                    <br />
                    {errors.errors?.map((error) =>
                      error.errors.map((err) => (
                        <Alert key={err} variant='danger'>
                          {err}
                        </Alert>
                      ))
                    )}
                  </PanelWithTitle>
                </GridItem>
              </Grid>
            </Fragment>
          )}
        </DefaultLayout>
      </Panel>
    </Fragment>
  );
});

Checkout.displayName = 'Checkout';

Checkout.propTypes = {};

Checkout.defaultProps = {};

export default withProviders(Checkout);
